import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { DownloadService } from '../../shared/services/download.service';
import { delayRequest } from '../../shared/util/delayed-request.util';

export interface SftpFile {
  path: string;
  fileSize: number;
  modified: Date;
}

export class LoadSftpFiles {
  static readonly type = '[AdminJobs] Load Files';
}

export class DownloadSftpFile {
  static readonly type = '[AdminJobs] Download SFTP File';
  constructor(public payload: { file: SftpFile }) {}
}

export class UploadSftpFile {
  static readonly type = '[AdminJobs] Upload SFTP File';
  constructor(public payload: { file: File; path: string }) {}
}

export class DeleteSftpFile {
  static readonly type = '[AdminJobs] Delete SFTP File';
  constructor(public payload: { file: SftpFile }) {}
}

export interface AdminFilesStateModel extends BaseStateModel {
  files: SftpFile[];
}

@State<AdminFilesStateModel>({
  name: 'adminFiles',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    files: [],
  },
})
export class AdminFilesState {
  private http = inject(HttpClient);
  private downloadService = inject(DownloadService);

  @Selector()
  static error(state: AdminFilesStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: AdminFilesStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: AdminFilesStateModel) {
    return state.loaded;
  }

  @Selector()
  static files(state: AdminFilesStateModel) {
    return state.files;
  }

  @Action(LoadSftpFiles)
  async loadFiles(
    ctx: StateContext<AdminFilesStateModel>,
    action: LoadSftpFiles,
  ) {
    ctx.patchState({ loading: true, error: null });
    try {
      const files = await lastValueFrom(
        this.http.get<SftpFile[]>(`${environment.apiUrl}/v1/admin-jobs/files`),
      );

      const mappedFiles = files
        .map((file) => ({
          ...file,
          modified: new Date(file.modified),
        }))
        .sort((a, b) => {
          if (a.path.includes('/') && !b.path.includes('/')) return -1;
          if (!a.path.includes('/') && b.path.includes('/')) return 1;
          return a.path.localeCompare(b.path);
        });

      ctx.patchState({
        files: mappedFiles,
      });
    } catch (err) {
      ctx.patchState({ error: err });
      throw err;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(DownloadSftpFile)
  async downloadSftpFile(
    ctx: StateContext<AdminFilesStateModel>,
    action: DownloadSftpFile,
  ) {
    try {
      const file = action.payload.file;
      const url = `${environment.apiUrl}/v1/admin-jobs/files/${encodeURIComponent(file.path)}`;
      const response = await lastValueFrom(
        this.http.get(url, { responseType: 'blob' }),
      );
      this.downloadService.downloadBlob(response, file.path);
    } catch (err) {
      throw err;
    } finally {
    }
  }

  @Action(UploadSftpFile)
  async uploadSftpFile(
    ctx: StateContext<AdminFilesStateModel>,
    action: UploadSftpFile,
  ) {
    try {
      let filePath = `${action.payload.path.endsWith('/') ? action.payload.path : `${action.payload.path}/`}${action.payload.file.name}`;
      if (filePath.startsWith('/')) filePath = filePath.substring(1);

      const formData = new FormData();
      formData.append('file', action.payload.file);
      await delayRequest(
        lastValueFrom(
          this.http.put(
            `${environment.apiUrl}/v1/admin-jobs/files/${encodeURIComponent(filePath)}`,
            formData,
          ),
        ),
      );

      ctx.dispatch(new LoadSftpFiles());
    } catch (err) {
      throw err;
    } finally {
    }
  }

  @Action(DeleteSftpFile)
  async deleteSftpFile(
    ctx: StateContext<AdminFilesStateModel>,
    action: DeleteSftpFile,
  ) {
    try {
      await delayRequest(
        lastValueFrom(
          this.http.delete(
            `${environment.apiUrl}/v1/admin-jobs/files/${encodeURIComponent(action.payload.file.path)}`,
          ),
        ),
      );

      ctx.dispatch(new LoadSftpFiles());
    } catch (err) {
      throw err;
    } finally {
    }
  }
}
