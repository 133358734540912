import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CompanyFeatureType, JobLog } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { mapCreatedUpdated } from '../../interfaces/base.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';

export interface JobItem {
  jobName: string;
  requiredFeatures: CompanyFeatureType[] | null;
  cronSchedule: string;
  latestJobLogs: JobLog[];
}

export interface RemainingExecutionsForPeriod {
  count: number;
  remaining: number;
  nextAllowedExecutionTime: Date;
}

export class LoadAdminJobs {
  static readonly type = '[AdminJobs] Load Admin Jobs';
}

export class ScheduleAdminJob {
  static readonly type = '[AdminJobs] Schedule Admin Job';
  constructor(public payload: { jobName: string }) {}
}

export interface AdminJobsStateModel extends BaseStateModel {
  jobs: JobItem[];
  remainingJobExecutions?: RemainingExecutionsForPeriod;
}

@State<AdminJobsStateModel>({
  name: 'adminJobs',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    jobs: [],
    remainingJobExecutions: undefined,
  },
})
export class AdminJobsState {
  private http = inject(HttpClient);

  @Selector()
  static jobs(state: AdminJobsStateModel) {
    return state.jobs;
  }

  @Selector()
  static remainingJobExecutions(state: AdminJobsStateModel) {
    return state.remainingJobExecutions;
  }

  @Selector()
  static error(state: AdminJobsStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: AdminJobsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: AdminJobsStateModel) {
    return state.loaded;
  }

  @Action(LoadAdminJobs)
  async loadAdminJobs(ctx: StateContext<AdminJobsStateModel>) {
    ctx.patchState({
      loading: true,
      error: null,
    });

    try {
      const res = await delayRequest(
        lastValueFrom(
          this.http.get<{
            jobItems: JobItem[];
            remainingJobExecutions: RemainingExecutionsForPeriod;
          }>(`${environment.apiUrl}/v1/admin-jobs`),
        ),
      );

      const mappedJobItems = res.jobItems.map((item) => ({
        ...item,
        latestJobLogs: item.latestJobLogs.map((log) => {
          return {
            ...log,
            ...mapCreatedUpdated(log),
          };
        }),
      }));

      ctx.patchState({
        jobs: mappedJobItems,
        remainingJobExecutions: {
          ...res.remainingJobExecutions,
          nextAllowedExecutionTime: new Date(
            res.remainingJobExecutions.nextAllowedExecutionTime,
          ),
        },
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  @Action(ScheduleAdminJob)
  async scheduleAdminJob(
    ctx: StateContext<AdminJobsStateModel>,
    action: ScheduleAdminJob,
  ) {
    try {
      await delayRequest(
        lastValueFrom(
          this.http.post<JobLog>(
            `${environment.apiUrl}/v1/admin-jobs/schedule`,
            {
              jobName: action.payload.jobName,
            },
          ),
        ),
      );

      ctx.dispatch(new LoadAdminJobs());
    } catch (err) {
      throw err;
    } finally {
    }
  }
}
